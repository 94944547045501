import React from 'react';
import './App.css';
import CarnetForm from './CarnetForm';  // Importa tu formulario

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <CarnetForm /> {/* Inserta tu formulario aquí */}
      </header>
    </div>
  );
}

export default App;
