import React from 'react';

const LogoComponent = () => {
  return (
    <div className="login-container">
      <div>
        <img src="/images/LogoAzul.png" alt="Logo" className="logo" style={{width: "50%", marginBottom: "2rem", marginTop: "2rem"}}/>
      </div>
    </div>
  );
};

export default LogoComponent;
