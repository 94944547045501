import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoComponent from './LogoComponent';

const CarnetForm = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    aka: '',
    genero: '',
    ciudad: '',
    perfilImagen: null,
    carnetCreado: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, perfilImagen: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que haya una imagen seleccionada
    if (!formData.perfilImagen) {
      toast.error("Por favor, selecciona una imagen de perfil");
      return;
    }

    const formDataObj = new FormData();
    formDataObj.append('nombre', formData.nombre);
    formDataObj.append('aka', formData.aka);
    formDataObj.append('genero', formData.genero);
    formDataObj.append('ciudad', formData.ciudad);
    formDataObj.append('perfilImagen', formData.perfilImagen);
    formDataObj.append('carnetCreado', formData.carnetCreado);

    try {
      const response = await fetch('https://backaleron.aleronclub.com/api/carnets', {
        method: 'POST',
        body: formDataObj
      });

      if (response.ok) {
        toast.success("Carnet creado correctamente");

        // Limpiar los campos del formulario
        setFormData({
          nombre: '',
          aka: '',
          genero: '',
          ciudad: '',
          perfilImagen: null,
          carnetCreado: false
        });

        // Restablecer el campo de archivo manualmente (para que desaparezca el nombre del archivo)
        document.getElementById("perfilImagen").value = null;
      } else {
        toast.error("Error al crear el carnet");
      }
    } catch (error) {
      toast.error("Error en la solicitud");
    }
  };

  return (
    <div style={{marginTop: "5rem"}} className="login-container">
      <div className="login-box">
        <LogoComponent />
        <form onSubmit={handleSubmit}>
          <input
            className="form-control"
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            placeholder="Nombre"
            required
          />
          <input
            className="form-control"
            type="text"
            name="aka"
            value={formData.aka}
            onChange={handleChange}
            placeholder="A.K.A"
            required
          />
          <input
            className="form-control"
            type="text"
            name="genero"
            value={formData.genero}
            onChange={handleChange}
            placeholder="Género"
            required
          />
          <input
            className="form-control"
            type="text"
            name="ciudad"
            value={formData.ciudad}
            onChange={handleChange}
            placeholder="Ciudad"
            required
          />
          <input
            id="perfilImagen"
            className="form-control"
            type="file"
            name="perfilImagen"
            onChange={handleFileChange}
            required
          />

          <button  style={{color: "white"}} className="btn-primary" type="submit">
            Crear Carnet
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CarnetForm;
